//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

html {
    font-size:17px;
    height: 100%;
    body {
        height: 100%
    }
    
}
.page-content{
    min-height: calc(100% - 71px);
    display:flex;
    flex-direction: column;
}
.main-content{
    flex-grow: 1;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";


.inner-narrow{
    max-width: 980px;
}
.navbar-brand-wrapper {
    width: 65%;padding-bottom: 10px;
}
.navbar-toggler {
    margin-bottom: 10px;
}
.nav-content {
    flex: 1 0 auto;
    padding-top: 10px;
    border-bottom:1px solid #dfdfdf;
    
}
.nav-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height:100%
}

.nav-item {

    position: relative;
    padding-bottom: 10px;
    font-size: 14px;
    color: #000;
    line-height: 16px;
    letter-spacing:-0.005em
    
}
.nav-link {
    color: #000;
    opacity: .8;
    transition:opacity 130ms ease-in-out
}
.nav-item.active::after{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 1px;

    background-color: #0f0f0f;
}
.nav-item.active .nav-link {
    opacity: 1;


}

.nav-link:hover {
    opacity:.5
}

.intro {
    margin-top: 1.2em;
    font-size: 21px;
    line-height: 1.381;
    font-weight: 400;
    letter-spacing: .011em;
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}
.intro-text {
    max-width: 800px;
    margin: 23px auto 0;
    font-size: 20px;
    color: #333;
    line-height: 1.48;
    letter-spacing: .001em;
}
.headline {
    max-width: 770px;
    margin: 0 auto;
    font-size: 52px;
    font-weight: 600;
    color: #000;
    line-height: 1;
    letter-spacing: -0.02em;
}
.main-content {
    padding-bottom: 140px !important;
}
.copyright {
    font-size: 12px;
    color: #86868b;
}
.footer__nav-links{
    margin-top: 12px;
    display: flex;
    flex-direction: column;
}
.footer__nav-link {
    font-size: 12px;
    color: #0f0f0f;
    letter-spacing: -0.01em;
    opacity: .8;
    transition: opacity 130ms ease-in-out;
    text-decoration: none;
}
footer{
    .footer-contact__title {
        font-size: 13px;
        font-weight: 600;
        color: #0f0f0f;
        letter-spacing: -0.01em;
    }
    .footer-contact__description{
        max-width: 280px;
        margin-top: 13px;
        font-size: 13px;
        color: #0f0f0f;
        line-height: 19px;
        letter-spacing: -0.01em;
        opacity: .8;
    }
}
.privacy__content {
    padding-top: 120px;
    min-height: 400px;
    p{
        margin-top: 6px;
        font-size: 17px;
        color: #3b3b3b;
        line-height: 1.45;
        letter-spacing: .002em;
    }
}
.privacy__content h2{
    margin-top: 44px;
    font-size: 19px;
    font-weight: 700;
    color: #0f0f0f;
    line-height: 1.45;
    letter-spacing: .001em;
}

.suport__content h2 {

    padding-bottom: 30px;
    font-size: 30px;
    font-weight: 700;
    color: #0f0f0f;
    line-height: 1.22;
    letter-spacing: .004em;
}

.support-faq__item-question {
    font-size: 19px;
    font-weight: 700;
    color: #0f0f0f;
    line-height: 1.45;
    letter-spacing: .001em;
    }
.support-faq__item-answer {
    margin-top: 6px;
    font-size: 17px;
    color: #3b3b3b;
    line-height: 1.45;
    letter-spacing: .002em;
    }